import fetcher from "lib/fetcher";
import Stack from "@mui/material/Stack";
import useSWR from "swr";
import List from "components/List";

export default function MyLists(props) {
  const apiPath = "/api/list/mine";
  const { data } = useSWR(apiPath, fetcher);

  return !data?.length ? (
    <div />
  ) : (
    <Stack spacing={2} marginTop={2}>
      {data
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .map((list) => (
          <List
            key={list.id}
            list={list}
            {...props}
            apiPath={apiPath}
            mine={true}
          />
        ))}
    </Stack>
  );
}
