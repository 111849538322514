import PageHeader from "components/PageHeader";
import MyLists from "components/MyLists";
import Button from "@mui/material/Button";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Stack from "@mui/material/Stack";
export default function Home(props) {
  const { data: session, status } = useSession();
  const router = useRouter();
  if (status == "unauthenticated") {
    router.push("/explore");
  }
  return (
    <div>
      <PageHeader>Home</PageHeader>
      <Stack direction="row" spacing={2} alignItems={"baseline"}>
        <Button onClick={() => props.createList()} variant="contained">
          New&nbsp;List&nbsp;+
        </Button>
        <div>Share five things that you love this week</div>
      </Stack>
      <MyLists {...props} />
    </div>
  );
}
//Home.auth = true;
