import Typography from "@mui/material/Typography";
export default function PageHeader(props) {
  return (
    <Typography
      flexGrow={1}
      variant="h2"
      sx={{ mb: 4, mt: 4, fontSize: { sm: "3.75rem", xs: "3rem" } }}
    >
      {props.children}
    </Typography>
  );
}
